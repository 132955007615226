.productItemContainer{
    min-width:300px;
    max-width: 270px ;
    height: 40vh;
    /* border: 1px solid var(--bg3); */
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* transition: all 1s; */
    overflow: hidden;
    border-radius: 2rem;
    /* margin-bottom: 2vmax; */
    text-decoration: none;
    padding: 0.8vmax ;
    box-shadow: -1px -1px 20px rgb(205, 205, 205);
 }

.productItemContainer:hover{
   /* margin-bottom: 0; */

    .read-btn{
        transition: all 0.4s;
        /* display: block; */
        margin-bottom: 0.5vmax;

    }
     .img-s{
        height: 50%;
    /* border: 1px solid red; */
    /* transform: scale(1.05); */
}
}
.productItemContainer > h3{
    font: 600 1.2vmax 'Roboto';
    color: var(--textC1);
    padding: 0.5vmax 0;
}

.productItemContainer > p{
    font: 300 1vmax 'Roboto';
    padding-bottom: 0.5vmax;
    color: var(--textC1);
}
.productItemContainer > img{
    height: 50%;
    margin: .4vmax;
    transition: all 0.5s;
    mix-blend-mode:darken;
    /* width: 100%; */
    /* height:65%; */
}
.read-btn{
    /* display: none; */
    width: 80%;
    /* position: absolute; */
    /* position: relative; */
    /* z-index: 1; */
    text-decoration: none;
    margin-bottom: -3vmax ;
    bottom: 0;
    border: none;
    padding: 0.5vmax;
    cursor: pointer;
    background-color: var(--bg3);
    border: 1px solid var(--bg1);
    transition: all 1s;
    border-radius: 2rem;
    
}
.read-btn:hover{
    background-color: var(--bg1);

    > p {
        color: var(--textC2);
    }
}
.read-btn > p {
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
}
.highlightBox{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* position: absolute; */
}
.highlightBox > p{
    width: 20%;
    background-color: var(--bg3);
    color: var(--textC2);
    font: 600 0.9vmax 'Roboto';
    padding: 0.2vmax;
}
@media screen  and (max-width:600px){
    .productItemContainer{
        min-width: 48%;
        max-width: 48%;
        height: auto;
        padding-bottom: 0;
        border-radius: 0;
        /* gap: 0.5rem; */
        /* border: 1px solid gray; */
        /* border: none; */
    border: 1px solid var(--bg4);
    box-shadow: 0 0 0; 

     }
     .productItemContainer > h3{
        font: 600 2.2vmax 'Roboto';
        color: var(--textC1);
        padding: 0.5vmax 0;
    }
    
    .productItemContainer > p{
        font: 300 1.8vmax 'Roboto';
        padding-bottom: 0.8vmax;
        color: var(--textC1);
    }
     .productItemContainer > img{
         width: 70%;
     }
     .read-btn{
        /* display: none; */
        bottom: 0;
        border: none;
        width: 100%;
        padding: 1vmax;
        cursor: pointer;
        background-color: var(--bg1);
        margin: 2vmax;
        margin-top: 0;
        transition: all 1s;
        /* border-radius: 0; */
        
    }
    .read-btn:hover{
        background-color: var(--bg3);
    }
    .read-btn > p {
        font: 300 1.8vmax 'Roboto';
        color: var(--textC2);
    }
    .highlightBox > p{
        width: 20%;
        background-color: red;
        color: var(--textC2);
        font: 600 1.4vmax 'Roboto';
        padding: 0.2vmax;
    }
    .productItemContainer:hover{
        /* margin-bottom: 0; */
     
         .read-btn{
             transition: all 0.4s;
             /* display: block; */
             margin-bottom: 2vmax;
     
         }
          .img-s{
             /* width: auto; */
         /* border: 1px solid red; */
         /* transform: scale(1.05); */
     }}
}