
    /* aboutContainerText*/

    .aboutContainerText{
        width: 100%;
        padding: 6vmax 10vmax;
    }
    .about-Box{
        display: flex;
        gap: 4rem;
        align-items: center;
        padding: 2vmax 0;
    }

    .aboutText{
        width: 50%;
        text-align: justify;
        word-spacing: 15px;
        font: 300 1.1vmax Roboto;
        color: var(--textC1);
    }
    .aboutImag{
        width: 45%;
        height: 400px;
        overflow: hidden;
        border-radius: 0.4rem;


    }
    .aboutImag > img{
        width: 100%;
    }
/* aboutContainerText */

 /* aboutContainer */

.borderPatti{
    /* background-color: var(--bg1); */
    color: var(--textC1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4vmax;
    font: 600 2vmax 'Roboto';
    border-bottom: 2px solid var(--textC1);
    border-radius: 2rem;
}

.visionBox{
    display: flex;
    padding: 3vmax 8vmax;
    gap: 2rem;
    justify-content: center;
    align-items: center;

}
.visionItem1{
    width: 30%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid gray; */
    padding: 3rem;
    border-radius: 2rem;
    /* box-shadow: 8px 8px 12px 2px gray; */

}
.visionItem1:hover{
    box-shadow: 0px 0px 15px rgb(190, 181, 255);
}
.visionItem{
    /* width: 30%;
    height: 60vh; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid gray; */
    padding: 5rem 3vmax;
    border-radius: 2rem;
    box-shadow: 8px 8px 12px 2px rgb(233, 211, 255);

}
.visionItem:hover{
    transition: all 0.5s;
    box-shadow: -8px -8px 12px 2px rgb(233, 211, 255);
}
.visionItem1 > svg{
    font-size: 3vmax;
    color: var(--textC1);
}

.visionItem > svg{
    font-size: 3vmax;
    color: var(--textC1);
}
.visionItem1 > h1,
.visionItem > h1{
    font: 600 2vmax 'Roboto' ;
    color: var(--textC1);
}
.visionItem1 > p{
    font: 300 1.1vmax 'Roboto' ;
    color: gray;
    text-align: justify;
}
.visionItem > p{
    font: 300 1.1vmax 'Roboto' ;
    color: gray;
    text-align: center;
}

.borderPatti2{
    /* background-image: url(https://media.licdn.com/dms/image/C5616AQEl2W6aEEE8Ag/profile-displaybackgroundimage-shrink_200_800/0/1638151678663?e=2147483647&v=beta&t=WzQuQQ87FKDdu5VsVKWNr61gvrpCHBbNJ-RFXWJpw-4); */
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    /* background-color: var(--bg1); */
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;


    
}
.borderPatti2 > p{

    /* background-color: rgba(0, 0, 0, 0.496); */
    width: 50%;
    color: var(--textC1);
    border-bottom: 2px solid var(--textC1);
    border-radius: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vmax;
    font: 600 2vmax 'Roboto';
    /* box-shadow: 10px 10px   10px 1px rgb(245, 227, 255); */
}
.c1{
    background-color: rgb(210, 255, 240);
}
.c2{
    background-color: rgb(206, 255, 214);
}
.c3{
    background-color: rgb(255, 253, 221);
}

/* about Section2 */
.AboutSection2 {
     background-image: url(https://www.nextvisual.com.my/wp-content/uploads/2017/11/about-us-banner-background-02-min.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    /* height: 100vh; */
    background-color: antiquewhite;
    /* padding: 1vmax 0; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.AboutS2Box{
background-color: rgba(32, 32, 32, 0.479);
    width: 100%;
    /* height: 80%; */
    padding: 6vmax 10vmax;
    /* border-radius: 4rem; */
    /* box-shadow: 0px 0px 15px var(--textC1); */
    /* background-color: rgba(0, 0, 0, 0.186); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}
.AboutS2Box > h1{
    font: 900 2.6vmax 'Roboto';
    text-align: center;
    text-transform: uppercase;
    /* background-color: rgb(242, 223, 255); */
    padding: 0.5vmax 1vmax;
    color: var(--textC2);
}
.AboutS2Box > p{
    /* width: 40% !important; */
    font: 300 2.4vmax 'Roboto';
    text-align: center;
    text-transform: uppercase;

    /* background-color: rgb(242, 223, 255); */
    /* padding: 0.5vmax 1vmax; */
    color: var(--textC2);
}

@media screen  and (max-width:600px){

    /* aboutContainerText*/
    
    .aboutContainerText{
        width: 100%;
        padding: 6vmax 2vmax;
    }
    .about-Box{
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        align-items: center;
        padding: 2vmax 0;
    }
    .aboutText{
        width: 100%;
        text-align: justify;
        word-spacing: 8px;
        font: 300 1.8vmax Roboto;
        color: var(--textC1);
    }
    .aboutImag{
        width: 100%;
    }
    .aboutImag > img{
        width: 100%;
    }
/* aboutContainerText */
    .borderPatti2{
      
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2rem;
    
    
        
    } 


    .borderPatti2 > p{

        /* background-color: rgba(0, 0, 0, 0.496); */
        width: 80%;
        color: var(--textC1);
        border-bottom: 2px solid var(--textC1);
        border-radius: 2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.2vmax;
        font: 600 2vmax 'Roboto';
        margin-bottom: 3vmax;
        /* box-shadow: 10px 10px   10px 1px rgb(245, 227, 255); */
    }
   
    .visionBox{
        display: flex;
        flex-direction: column;
        padding: 3vmax 1vmax;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    
    }
    .visionItem1{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        /* border: 1px solid gray; */
        padding: 2rem;
        border-radius: 2rem;
        /* box-shadow: 8px 8px 12px 2px gray; */
    
    }
    .visionItem1 > svg{
        font-size: 5vmax;
        color: var(--textC1);
    }
    
    .visionItem > svg{
        font-size: 5vmax;
        color: var(--textC1);
    }
    .visionItem1 > h1,
.visionItem > h1{
    font: 600 2.6vmax 'Roboto' ;
    color: var(--textC1);
}
.visionItem1 > p{
    font: 300 1.7vmax 'Roboto' ;
    color: gray;
    text-align: justify;
}
.visionItem > p{
    font: 300 1.7vmax 'Roboto' ;
    color: gray;
    text-align: center;
}
/* about Section2 */
.AboutSection2 {
    background-image: url(https://www.nextvisual.com.my/wp-content/uploads/2017/11/about-us-banner-background-02-min.jpg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   width: 100%;
   /* height: 100vh; */
   background-color: antiquewhite;
   /* padding: 1vmax 0; */
   display: flex;
   align-items: center;
   justify-content: center;

}
.AboutS2Box{
background-color: rgba(32, 32, 32, 0.479);
   width: 100%;
   /* height: 80%; */
   padding: 6vmax 1vmax;
   /* border-radius: 4rem; */
   /* box-shadow: 0px 0px 15px var(--textC1); */
   /* background-color: rgba(0, 0, 0, 0.186); */
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1.5rem;
}
.AboutS2Box > h1{
   font: 900 2.6vmax 'Roboto';
   text-align: center;
   /* background-color: rgb(242, 223, 255); */
   padding: 0.5vmax 1vmax;
   color: var(--textC2);
}
.AboutS2Box > p{
   /* width: 40% !important; */
   font: 300 1.7vmax 'Roboto';
   text-align: justify;
   /* background-color: rgb(242, 223, 255); */
   /* padding: 0.5vmax 1vmax; */
   color: var(--textC2);
}
}