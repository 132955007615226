.tableBox{
    width: 100vw;
    padding: 3vmax 7vmax;
    background-color: rgb(244, 244, 244);
}
.tableBoxMain{
    overflow: auto;
}
.tableHeader{
    min-width: 1250px;
   
    text-decoration: none;
    display: flex;
    /* border: 1px solid gray; */
    padding: 1vmax 2vmax;
    justify-content: space-between;
    margin: 0.2vmax 0;

}
.itemBox:hover{
    transition: all 0.5s;
    background-color: rgb(221, 221, 221);
}
.s-no{
    width: 20%;
    color: white;
    /* border: 1px solid gray; */
    
    text-align: left !important;
}
.s-no1{
    width: 20%;
    color: rgb(130, 130, 130);
    /* border: 1px solid gray; */
    
    text-align: left !important;
}
.tableItem{
    width: 80%;
    color: white;
    /* border: 1px solid gray; */
    
    text-align: left !important;
}
.tableItem1{
    width: 80%;
    color: rgb(80, 80, 80);
    text-align: left !important;

}
.tableItemProduct{
    width: 60%;
    color: white;    
    text-align: left !important;
}
.tableItemProduct1{
    width: 60%;
    color: rgb(80, 80, 80);
    text-align: left !important;

}
.tableItemChamical{
    width: 50%;
    color: white;    
    text-align: left !important;
}
.tableItemChamical1{
    width: 50%;
    color: rgb(80, 80, 80);
    text-align: left !important;

}
.bg{
    background-color: var(--bg1);
    box-shadow: 0 0 10px 1px rgb(221, 221, 221);

}
.bg1{
    background-color: var(--bg3);
}

.bg2{
    background-color: rgb(242, 238, 255);
    > div{
        /* color: white; */
    }
}
/* ------------------------------------------------------- */
.search-Box{

    padding: 1vmax 7vmax;
    padding-top: 3vmax;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* gap: 1rem; */

}
.search-Box > input{
    width: 22%;
    padding: 0.7vmax 1.5vmax;
    font: 300 0.9vmax 'Roboto';
    outline: none;
    border: none;
    box-shadow: 0 0 10px rgb(240, 240, 240);
}
.search-Box > button{
    /* width: 22%; */
    padding: 0.7vmax 2vmax;
    font: 300 0.9vmax 'Roboto';
    outline: none;
    border: none;
    background-color: var(--bg1);
    color: white;
    cursor: pointer;
    box-shadow: 0 0 10px rgb(240, 240, 240);
}

@media screen and (max-width:600px){
    .tableBox{
        width: 100vw;
        padding: 3vmax 1vmax;
        background-color: rgb(244, 244, 244);
    }
    /* ------------------------------------------------------- */
.search-Box{

    padding: 1vmax 1vmax;
    padding-top: 3vmax;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 1rem; */

}
.search-Box > input{
    width: 80%;
    padding: 1vmax 3vmax;
    font: 300 1.9vmax 'Roboto';
    outline: none;
    border: none;
    box-shadow: 0 0 10px rgb(240, 240, 240);
}
.search-Box > button{
    /* width: 22%; */
    padding: 1vmax 3vmax;
    font: 300 1.9vmax 'Roboto';
    outline: none;
    border: none;
    background-color: var(--bg1);
    color: white;
    cursor: pointer;
    box-shadow: 0 0 10px rgb(240, 240, 240);
}
}