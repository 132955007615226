/* topSection */
.center{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vmax 0;
}
.btnBg{
    background-color: var(--textC1) !important;
    color: var(--textC2) !important;
}
.btnBg:hover{
    background-color: var(--textC2) !important;
    color: var(--textC1) !important;
}
.topSection {
    width: 100%;
    height: 100vh;

    background-image: url(../images//banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 5vmax; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 0 0 4rem 4rem;
    overflow: hidden; */

}
.containBox{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.186);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
    padding: 4vmax;
}
.s2Box > h1,
.containBox > h1{
    width: 50%;
    font: 900 5vmax 'Roboto';
    text-align: right;
    /* background-color: rgb(242, 223, 255); */
    padding: 0.5vmax 1vmax;
    color: var(--textC2);
}
.containBox > p{
    width: 40% !important;
    font: 300 1.4vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    /* padding: 0.5vmax 1vmax; */
    color: var(--textC2);
}

.button{
    text-decoration: none;
    padding: 0.6vmax 2vmax;
    font: 300 1vmax 'Roboto';
    text-align: center;
    background-color: var(--textC2);
    color: var(--textC1);
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 10px 2px rgb(249, 180, 255);
}
.button:hover{
    transition: all 0.5s;
    cursor: pointer;
    background-color: var(--textC1);
    color: var(--textC2);
}

/* topSection end */
/* Section2 */
.Section2 {
    width: 100%;
    /* height: 100vh; */

    padding: 1vmax 0;
    display: flex;
    align-items: center;
    justify-content: center;

}
.s2Box{
    width: 70%;
    /* height: 80%; */
    padding: 6vmax;
    border-radius: 4rem;
    /* box-shadow: 0px 0px 15px var(--textC1); */
    /* background-color: rgba(0, 0, 0, 0.186); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}
.s2Box > h1{
    font: 900 2.6vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    padding: 0.5vmax 1vmax;
    color: var(--textC1);
}
.s2Box > p{
    /* width: 40% !important; */
    font: 300 1.4vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    /* padding: 0.5vmax 1vmax; */
    color: var(--textC1);
}
.s2Box1 {
    /* background-color: var(--textC1); */
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
    /* padding: 0 6vmax ; */
    padding: 6rem 0;
    /* background-image: linear-gradient(15deg,var(--bg3),white,var(--bg3)) ; */
    /* border-radius: 4rem; */

}
.s2Box1 > img{
    /* mix-blend-mode: darken; */
    /* padding: 1rem; */
}
.s2Box1 > h3{
    width: 55%;
    font: 600 2.2vmax 'Roboto';
    color: var(--textC2);
    padding: 2vmax;
    /* border-radius: 2rem; */
    /* border-bottom: 1px solid var(--textC1); */
}
/* Section2 end */

.heading{
    width: 100%;
    padding: 2vmax 0;
    /* background-color: var(--textC1); */

}
.heading > h1{
    font: 600 2.2vmax 'Roboto';
    color: var(--textC1);
    text-align: center;
    /* padding-left: 6.5vmax; */
}
.home-product-Box{
    width: 100%;
    padding: 2vmax 0 ;
    display: flex;
    /* gap: 1rem; */
    align-items: center;
    justify-content: space-evenly;

}
.home-product-Box .productItem{
    text-decoration: none;
    width: 24%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid gray; */
    box-shadow: 0 0 10px rgb(212, 193, 255);
    /* padding: 1.5vmax; */
    padding-bottom: 1.5vmax;
    border-radius: 1.5rem;
    overflow: hidden;
}
.home-product-Box .productItem:hover{
    /* margin-top: -15px; */
    cursor: pointer;
    transition: all 0.5s;
    background-color: var(--textC1);
     > p{
        color: white;
     }
}

.home-product-Box .productItem > img{
    width: 100%;
    /* height: 240px; */
    /* border-radius: 0.4rem; */

}
.home-product-Box .productItem > p{
    font: 600 1.2vmax 'Roboto';
    color: var(--textC1);
    padding: 1vmax 0;
}
@media screen and (max-width:600px) {
    /* topSection */
.topSection {
    width: 100%;
    height: 40vh;

}
.s2Box > h1,
.containBox > h1{
    font: 900 2.7vmax 'Roboto';
    
}
.containBox > p{
    width: 80% !important;
    font: 300 1.7vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    /* padding: 0.5vmax 1vmax; */
    color: var(--textC2);
}
.button{
    padding: 0.9vmax 3vmax;
    font: 300 1.6vmax 'Roboto';
    text-align: center;
    background-color: var(--textC2);
    color: var(--textC1);
    border: none;
    border-radius: 2rem;
    box-shadow: 1px 1px 10px 2px rgb(249, 180, 255);
}
/* topSection end */
/* Section2 */
.Section2 {
    width: 100%;
    /* height: 100vh; */

    padding: 1vmax 0;
    display: flex;
    align-items: center;
    justify-content: center;

}
.s2Box{
    width: 100%;
    /* height: 80%; */
    padding: 5vmax 1vmax;
    border-radius: 4rem;
    gap: 1rem;
}
.s2Box > h1{
    font: 900 2.8vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    padding: 0.5vmax 1vmax;
    color: var(--textC1);
}
.s2Box > p{
    /* width: 40% !important; */
    font: 300 1.7vmax 'Roboto';
    text-align: center;
    /* background-color: rgb(242, 223, 255); */
    /* padding: 0.5vmax 1vmax; */
    color: var(--textC1);
}
.s2Box1 {
    text-align: center !important;
    gap: 1rem;
    padding:6vmax 0 ;
    /* border-radius: 4rem; */

}
.s2Box1 > img{
    /* mix-blend-mode: darken; */
    /* padding: 0 1rem; */
}
.s2Box1 > h3{
    width: 60%;
    font: 600 2vmax 'Roboto';
    /* color: var(--textC1); */
    padding: .2vmax;
    /* border-radius: 2rem;
    border-bottom: 1px solid var(--textC1); */
}
/* Section2 end */
.heading{
    width: 100%;
    padding: 2vmax 0;
    /* background-color: var(--textC1); */

}
.heading > h1{
    font: 600 2.5vmax 'Roboto';
    color: var(--textC1);
    text-align: center;
}
.home-product-Box{
    width: 100%;
    padding: 1vmax 3vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;

}
.home-product-Box .productItem{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid gray; */
    box-shadow: 0 0 10px rgb(212, 193, 255);
    /* padding: 2.5vmax; */
    /* padding-bottom: 2vmax; */
    border-radius: 1.5rem;
}
.home-product-Box .productItem:hover{
    margin-top: -0px;
    cursor: pointer;
    transition: all 0.5s;
    background-color: var(--textC1);
     > p{
        color: white;
     }
}
.home-product-Box .productItem > img{
    width: 100%;
    height: 180px;
    /* border-radius: 0.4rem; */

}
.home-product-Box .productItem > p{
    font: 600 1.9vmax 'Roboto';
    color: var(--textC1);
}
}