.contactTop{
    /* background-image: url(https://www.petorosealcones.com/images/woman-working-call-center-min.jpg); */
    background-image: url(https://www.toyotaformsindia.in/wp-content/uploads/2018/01/Contact-Us-Background.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.infoContainer{
   background-color: rgba(0, 0, 0, 0.512);
    width: 100vw;
    display: flex;
    padding: 3vmax 0 ;
    /* background-color: var(--bg4); */
    margin-top: 1px;
}
.infoBox1{
    width: 50%;
    padding-right:  4vmax;
    display: flex;
    padding: 5vmax;
    /* justify-content: space-evenly; */
    gap: 1.2rem;
    flex-direction: column;
}
.infoBox1 > span{
    display: block;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-left: 4vmax;
}
.infoBox1 > span > svg{
    color: var(--bg1);
    font-size: 1.5vmax;
}
.infoBox1 > span > p{
    color: white;
}
.infoBox1 > div{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.infoBox1 > div > div{
    width: 3.7vmax;
    height: 1.5px;
    background-color: var(--bg1);
}
.infoBox1 > div > p{
   font-size: 1.3vmax;
   font-weight: 600;
   color: var(--bg1);
   /* letter-spacing: 2px; */
}
.infoBox1 > h1{
    padding-left: 4vmax;
    font: 700 2vmax 'Roboto';
    width: 80%;
    color: var(--bg1);
}
.infoBox1 > p{
    padding-left: 4vmax;
    font: 300 1.2vmax 'Roboto';
    width: 75%;
    line-height: 25px;
    color: var(--textC1);
}
.infoBox2{
    width: 50%;
    /* height: 100; */
    /* padding: 0 3vmax; */
    padding: 5vmax;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* background-color: var(--bg3); */
}
.infoBox2 > div {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.infoBox2 > div > h3{
    font: 700 1.4vmax 'Roboto';
    color: var(--bg1);
}
.infoBox2 > div > p{
    font: 300 1vmax 'Roboto';
}
.infoBoxItem3 > a{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    text-decoration: none;
    color: var(--textC1);
    font-family: 'Roboto';
    font-size: 1.1vmax;
}
.infoBoxItem3 > a > svg{
    color: rgb(17, 17, 17);
}

/* form Css */
    .formContainer{
        width: 100vw;
        padding: 3vmax 5vmax;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .formBox{
        width: 44vw;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .formBox > h2{
        width: 50%;
        margin: 0 auto;
        font: 600 2vmax 'Roboto';
        text-align: center;
        color: var(--bg1);
        padding-bottom: 0.5vmax;
        /* border-bottom: 1px solid var(--bg1); */
        border-radius: 2rem;
        margin-bottom: 1rem;
    }
    .formBox > div{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
   
    .formBox > div>input{
        width: 100%;
        padding: 1vmax ;
        border: none;
        border: 1px solid gray;
        font: 500 1vmax 'Roboto';
        outline: none;
        color: var(--textC1);
        /* border-radius: 0.2rem; */
        border-radius: 0.4rem;

    }
    .textAreaBox{
        display: flex;
        flex-direction: column;
        gap: 0.5rem !important;

    }
    .textAreaBox > label{
        font-family: 'Roboto';
        color: var(--textC1);
    }
    .textAreaBox > textarea{
        font-family: 'Roboto';
        color: var(--textC1);
        padding: 1vmax;
        outline: none;
        border-radius: 0.4rem;


    }
    .submitBtn{
        /* width: 6vmax; */
        text-align: center;
        margin: 0 auto;
        padding: 1vmax 3vmax;
        background-color: var(--bg1);
        border: none;
        border-radius: 2rem;
        font: 600 1vmax 'Roboto';
        color: var(--textC2);
        transition: all 0.5s;
        cursor: pointer;
        box-shadow: 0 0.5px 8px rgb(203, 203, 203);
    }
    .submitBtn:hover{
        background-color: var(--textC1);
    }
/* form Css end */
@media screen and  (max-width:800px){
    .infoContainer{
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 0.5vmax;
    background-color: var(--bg2);
    gap: 2rem;
}
.infoBox1{
    width: 100%;
    padding-right:  4vmax;
    display: flex;
    /* justify-content: space-evenly; */
    gap: 1.2rem;

    flex-direction: column;
}
.infoBox1 > div > p{
   font-size: 2vmax;
   font-weight: 600;
   letter-spacing: 2px;
}
.infoBox1 > h1{
    padding-left: 4vmax;
    font: 700 3vmax 'Roboto';
    width: 100%;
}
.infoBox1 > span > svg{
    color: var(--bg1);
    font-size: 3.5vmax;
}
.infoBox1 > p{
    padding-left: 4vmax;
    font: 300 2vmax 'Roboto';
    width: 100%;
    line-height: 26px;
}
.infoBox2{
    width: 100%;
    padding:  4vmax 0;
    padding-left: 4vmax;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-top: 1px solid rgb(198, 198, 198);
}
.infoBox2 > div > h3{
    font: 700 2.4vmax 'Roboto';
}
.infoBox2 > div > p{
    font: 300 1.65vmax 'Roboto';
}
.infoBoxItem3 > a{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    text-decoration: none;
    color: var(--textC1);
    font-family: 'Roboto';
    font-size: 1.8vmax;
}

/* form Css */
.formContainer{
    width: 100vw;
    padding: 5vmax 3vmax;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formBox{
    width: 100vw;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.formBox > h2{
    width: 100%;
    margin: 0 auto;
    font: 600 2.8vmax 'Roboto';}
.formBox > div>input{
    width: 100%;
    /* padding: 1.5vmax 0; */
    /* border: none; */
    /* border-bottom: 1px solid gray; */
    font: 500 2vmax 'Roboto';
    outline: none;
    color: var(--textC1);

}
.submitBtn{
    padding: 1vmax 4vmax;
    font: 600 2vmax 'Roboto';

}
}