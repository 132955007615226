.homeContainer {
    background-color: rgba(250, 255, 253, 0.801);
}

.carousalBox {
    width: 100%;
    height: auto !important;
}

.url1 {
    /* background-image: url(../images/bg-slide2.jpg); */
}

.url2 {
    /* background-image: url(../images/bg-slide1.jpg); */

}

.carousalItemCenter {
    background-image: linear-gradient(to right, var(--bg1), var(--bg3));
    background-color: rgb(20, 20, 20) !important;
    /* height: 100% ; */
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 2px;

}

.carousalItemCenter>img {
    /* height: 73.5vh; */
}

.carousalItemCenter>video {
    width: 100%;
    /* height: 67vh ; */
}

.carousalItem {
    /* height: auto; */
    /* background-color: var(--bg3); */
    padding: 2vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousalItem>div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 3vmax;
    gap: 1rem;
    transition: all 1s;
    animation: itemboxAni 1s 1;

}

.sec1Layer {
    background-image: url('../images/product-banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 20vh; */
}

.aboutusBg {
    background-image: url(https://static.vecteezy.com/system/resources/previews/003/497/193/original/internet-cyber-security-connection-concept-background-free-vector.jpg) !important;
}

.sec1Heading {
    width: 100%;
    height: 44vh;
    /* background-color: rgba(0, 0, 0, 0.082); */
    padding: 1vmax 1vmax;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.sec1Heading>h2 {
    /* border-bottom: 2px solid var(--textC2); */
    font: 600 2vmax 'Roboto';
    /* border: 1px solid var(--bg3); */
    color: var(--textC1);
    padding: 0.6vmax 1vmax;
    /* background-color: var(--bg1); */
}

@keyframes itemboxAni {
    from {
        transform: scale(1.2);


    }

    to {
        transform: scale(1);


    }

}

.carousalItem>div>h2 {
    width: 60%;
    text-align: left;
    font: 600 2vmax 'Roboto';
    color: var(--textC2);
}

.view-btn {
    width: 30%;
    padding: 0.8vmax 1vmax;
    /* background-color: var(--bg2); */
    border: none;
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: .2rem;
    box-shadow: 1px 1px 5px rgb(211, 211, 211);

}

.view-btn1 {
    width: 15%;
    height: 50px;
    padding: 0.2vmax 1vmax;
    background-color: var(--bg3);
    border: none;
    font: 600 1vmax 'Roboto';
    color: var(--textC2);
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: .2rem;
    box-shadow: 1px 1px 5px rgb(211, 211, 211);
}

.view-btn1:hover,
.view-btn:hover {
    background-color: var(--bg1);
    color: var(--textC2);
    box-shadow: 0 0 5px gray;
    /* border-left: 4px solid rgb(216, 216, 216); */

}

.carousalItem>img {
    width: 50% !important;
    height: auto;
    padding: 2vmax;
}

/* section2 */
.section2 {
    width: 100%;
    padding: 3vmax 10vmax;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border-bottom: 1px solid var(--textC3);
        border-top: 1px solid var(--textC3); */
    background-color: var(--bg4);
}

.section2>div {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.section2>div>span {

    /* padding: 0.5vmax; */
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--bg3);
}

.section2>div>span>svg {
    color: var(--bg1);
    font-size: 1.4vmax;
}

.section2>div>p {
    color: var(--bg1);
    /* font-size: 1.2vmax; */
    font: 700 1.2vmax 'Roboto';

}

/* section2 end */
/* .section3 */
.section3 {
    width: 100%;
    padding: 6vmax 4vmax;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border-bottom: 1px solid var(--textC3);
        border-top: 1px solid var(--textC3); */
    margin: 0 auto;
    border-radius: 0.5rem;
    background-color: var(--bg4);
}

.section3>div {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.section3>div>span {


    display: flex;
    flex-direction: column;

}

.section3>div>svg {
    color: var(--bg1);
    font-size: 3vmax;
}

.section3>div>span>h4 {
    color: var(--bg1);
    /* font-size: 1.2vmax; */
    font: 300 1.6vmax 'Roboto';

}

.section3>div>span>p {
    color: var(--bg1);
    /* font-size: 1.2vmax; */
    font: 300 1vmax 'Roboto';

}

/* .section3 end */
/* product-innov-container */
.hide {
    display: none !important;
}

.product-innov-container {
    width: 100%;
    padding: 0vmax 0;
    padding-bottom: 5vmax;
    /* border-top: 1px solid var(--bg2); */
}

.product-innov-container>h1 {
    width: 100%;
    text-align: center;
    font: 600 3.5vmax 'Roboto';
    color: var(--bg1);
    padding: 1vmax 0;
    border-top: 1px solid var(--bg2);
    border-bottom: 1px solid var(--bg2);

}

.pn-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3vmax;
}

.pn-nav>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
}

.actionBtnActive {

    border-bottom: 2px solid var(--bg1);
}

.pn-nav>div svg {
    font-size: 2vmax;
    color: var(--bg1);
}

.pn-nav>div h3 {
    font: 600 1.2vmax 'Roboto';
    color: var(--bg1);
}

.pn-content {
    width: 100%;
    height: 85vh;
    overflow: hidden;
    display: flex;
    padding: 0 15vmax;
    gap: 1rem;
}

.pn-c-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pn-c-item>img {
    height: 100%;
}

.pn-c-item>div {
    height: 50%;
    /* background-color: aliceblue; */
}

.pn-c-item-1 {
    background-color: var(--bg1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vmax;
    gap: 0.5rem;
}

.pn-c-item-1>h3 {
    font: 600 2vmax 'Roboto';
    color: var(--textC2);
}

.pn-c-item-1>a {
    width: 32%;
    padding: 1vmax;
    text-decoration: none;
    font: 300 1.1vmax 'Roboto';
    border: 2px solid var(--textC2);
    color: var(--textC2);
    transition: all 0.5s;
    text-align: center;
}

.pn-c-item-1>a:hover {
    border: 2px solid var(--textC1);
    color: var(--textC1);
}

.pn-c-item-2 {
    display: flex;
    width: 100%;
    gap: 1rem;
}

.product-1 {
    background-color: var(--bg2);
    border: 1px solid var(--bg2);
    padding: 1.5rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.product-1>img {
    width: 80%;
    margin: 0 auto;
    mix-blend-mode: darken;
}

.product-1>a {
    width: 50%;
    padding: 0.5vmax;
    text-decoration: none;
    font: 300 1vmax 'Roboto';
    /* border: 2px solid var(--textC1); */
    background-color: var(--bg3);
    color: var(--textC2);
    transition: all 0.5s;
    text-align: center;
}

/* product-innov-container end */
/* productsBox */
.productsBox {
    width: 100%;
    /* height: auto; */
    /* min-height: 50vh; */
    /* padding: 2vmax; */
    padding: 5vmax 6vmax;



    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.products-row {
    width: 100%;
    min-height: 70vh;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2vmax 0;
}

.productsBox>h1 {
    text-align: center;
    padding-bottom: 3vmax;
    font: 600 2vmax 'Roboto';
    color: var(--textC1);
    /* border-bottom: 1px solid var(--bg3); */
}

.btn-box {
    text-align: center;
}

/* productsBox end  */
/* reviewCotainer */
.reviewCotainer {
    width: 100%;
    padding: 4vmax 10vmax;
}

.reviewSection {
    width: 100%;
    overflow-x: scroll;
    padding: 2vmax 0;
    display: flex;
    gap: 1rem;

}

.reviewSection::-webkit-scrollbar {
    display: none;
}

.reviewitem {
    min-width: 32.1%;
    /* background-color: var(--bg4); */
    border: 1px solid var(--bg3);
    padding: 1.5rem;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.reviewitem>div {
    display: flex;
    /* justify-content: ; */
    align-items: center;
    gap: 2rem;
}

.reviewitem>div>span {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 0.5rem;
    border: 2px dotted var(--bg1);
}

.reviewitem>div>p {
    text-align: justify;
    font: 300 1.1vmax 'Roboto';
}

.bg-border {
    padding: 0.5rem;
    border-radius: 0.4rem;
    font: 300 1vmax 'Roboto' !important;

    background-color: var(--bg4);
}

.scrollBar {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.scrollBar>div {
    width: 60px;
    height: 5px;
    border-radius: 2rem;
    background-color: var(--bg1);
}

/* reviewCotainer end */

/* aboutBox */
.aboutBox {
    width: 100%;
    padding: 2vmax 10vmax;
    display: flex;
    gap: 3rem;
    /* background-color: rgb(232, 232, 232); */
    /* background: url(../images/Colored\ Shapes\ \(4\).svg); */

}

.aboutBox>div {
    width: 50%;
    /* padding: 1vmax 0; */
}

.aboutBox>div:first-child {
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    gap: 0.5rem;
}

.aboutBox>div:first-child>p,
.aboutBox>div:first-child>h2,
.aboutBox>div:first-child>h4 {
    /* font: 300 1.2vmax 'Roboto'; */
    font-family: 'Roboto';
    text-align: justify;
    color: var(--textC1);
    line-height: 22px;
}

.aboutBox>div:first-child>h2 {
    font: 600 2vmax 'Roboto';

}

.aboutBox>div:last-child {
    padding: 0vmax;
    text-align: center;
    padding-top: 0;
    /* background-color: aquamarine; */
    /* width: 100%; */
}

.aboutBox>div:last-child>img {
    height: 80%;
    /* box-shadow: 20px 20px 5px var(--textC2); */
}

/* aboutBox end */

/* contactBox */
.contactBox {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 3vmax;
}

.cItem {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--bg3); */
    border-radius: .4rem;
    /* padding: 1.8vmax 2.5vmax; */
    box-shadow: 0 0px 25px var(--textC3);
    overflow: hidden;
}

.cItem>div:first-child {
    padding: 1.5vmax 2.1vmax;
    background-color: var(--bg1);
}

.cItem>div>svg {
    font-size: 2.5vmax;
    color: var(--bg2);
    /* background-color: aqua; */

}

.cItem>div:last-child {
    /* padding: 1.8vmax 2.5vmax; */
    padding-right: 2vmax;

}

.cItem>div:last-child>h1 {
    font: 600 1.65vmax 'Roboto';
    color: var(--textC1);
}

.cItem>div:last-child>p {
    font: 500 1vmax 'Roboto';
    color: var(--textC1);
}

/* contactBox end */

@media screen and (max-width:600px) {
    .sec1Layer {
        
        height: 20vh;
    }
    .sec1Heading {
        width: 100%;
        /* height: 30vh; */
        /* background-color: rgba(0, 0, 0, 0.248); */
        /* padding: 6vmax; */
        display: flex;
        justify-content: center;
        align-items: flex-end;

    }

    .sec1Heading>h2 {
        /* border-bottom: 2px solid var(--textC2); */
        font: 600 3.4vmax 'Roboto';
        color: var(--textC2);
        padding: 1vmax 3vmax;
    }

    .carousalItemCenter {
        /* background-image: linear-gradient(to right, var(--bg1), var(--bg3)); */
        /* background-color: rgb(20, 20, 20); */
        display: flex;
        flex-direction: column-reverse;
        gap: 2px;

    }

    .carousalItemCenter>img {
        width: 100px;
        height: auto;
    }

    .carousalItem {
        height: 67vh;
        padding: 2vmax;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .carousalItem>div {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-;
        padding: 0 3vmax;
        gap: 1rem;

    }

    .carousalItem>div>h2 {
        width: 90%;
        text-align: left;
        font: 600 3vmax 'Roboto';
    }

    .view-btn {
        width: 45%;
        padding: 0.8vmax 1vmax;
        border: none;
        font: 600 2vmax 'Roboto';


    }


    .view-btn1 {
        width: 30%;
        height: 37px;
        padding: 1vmax 1vmax;
        background-color: var(--bg3);
        border: none;
        font: 600 1.6vmax 'Roboto';
        color: var(--textC2);
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
        border-radius: .2rem;
        box-shadow: 1px 1px 5px rgb(211, 211, 211);
    }

    .carousalItem>img {
        width: 100% !important;
        height: auto;
        padding: 3vmax;
    }

    /* section2 */
    .section2 {
        width: 100%;
        padding: 3.5vmax 1vmax;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* border-bottom: 1px solid var(--textC3);
        border-top: 1px solid var(--textC3); */
    }

    .section2>div {
        display: flex;
        flex-direction: column;

        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .section2>div>span {

        /* padding: 0.5vmax; */
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--bg3);
    }

    .section2>div>span>svg {
        color: var(--bg1);
        font-size: 2vmax;
    }

    .section2>div>p {
        color: var(--bg1);
        /* font-size: 1.2vmax; */
        font: 900 1.4vmax 'Roboto';
        text-align: center;

    }

    /* .section3 */
    .section3 {
        width: 98%;
        padding: 5vmax 1vmax;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        /* border-bottom: 1px solid var(--textC3);
        border-top: 1px solid var(--textC3); */
        gap: 2rem;
        margin: 0 auto;
        border-radius: 0.5rem;
        background-color: var(--bg4);
    }

    .section3>div {
        display: flex;
        /* flex-direction: column; */
        gap: 1.5rem;
        justify-content: space-evenly;
        align-items: center;
    }

    .section3>div>svg {
        color: var(--bg1);
        font-size: 5vmax;
    }

    .section3>div>span>h4 {
        color: var(--textC1);
        /* font-size: 1.2vmax; */
        font: 300 2.8vmax 'Roboto';

    }

    .section3>div>span>p {
        color: var(--textC1);
        /* font-size: 1.2vmax; */
        font: 300 1.8vmax 'Roboto';

    }

    /* .section3 end */

    /* product-innov-container */
    .product-innov-container {
        width: 100%;
        padding: 4vmax 0;
    }

    .product-innov-container>h1 {
        width: 100%;
        text-align: center;
        font: 600 3vmax '';
        color: var(--bg1);
    }

    .pn-nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 3vmax;
    }

    .pn-nav>div svg {

        font-size: 3vmax;
        color: var(--bg1);
    }

    .pn-nav>div h3 {
        font: 600 1.5vmax 'Roboto';
        color: var(--bg1);
    }

    .pn-content {
        width: 100%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0 1vmax;
        gap: 1rem;
    }

    .pn-c-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .pn-c-item>div {
        height: auto;
        /* background-color: aliceblue; */
    }

    .pn-c-item-1 {
        background-color: var(--bg1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4vmax;
        gap: 0.5rem;
    }

    .pn-c-item-1>h3 {
        font: 600 3vmax 'Roboto';
        color: var(--textC2);
    }

    .pn-c-item-1>a {
        width: 42%;
        padding: 1vmax;
        text-decoration: none;
        font: 300 1.8vmax 'Roboto';
        border: 2px solid var(--textC2);
        color: var(--textC2);
        transition: all 0.5s;
        text-align: center;
    }

    .pn-c-item-2 {
        display: flex;
        width: 100%;
        gap: 1rem;
    }

    .product-1>img {
        width: 95%;
        margin: 0 auto;
        mix-blend-mode: darken;
    }

    .product-1>p {
        font: 300 1.8vmax 'Roboto';
    }

    .product-1>a {
        width: 60%;
        padding: 0.5vmax;
        text-decoration: none;
        font: 300 1.8vmax 'Roboto';
        /* border: 2px solid var(--textC1); */
        background-color: var(--bg3);
        color: var(--textC2);
        transition: all 0.5s;
        text-align: center;
    }

    /* product-innov-container end */
    /* contactBox */
    .contactBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        padding: 3vmax;
    }

    .cItem:last-child {
        /* width: 100%; */
        display: flex;
        flex-direction: row-reverse;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;
        /* border: 1px solid var(--bg3); */
        border-radius: .4rem;
        /* padding: 1.8vmax 2.5vmax; */
        box-shadow: 0 0px 25px var(--textC3);
        overflow: hidden;
    }

    .cItem>div:first-child {
        padding: 2.5vmax 3.1vmax;
        background-color: var(--bg1);
    }

    .cItem>div>svg {
        font-size: 4.5vmax;
        color: var(--bg2);
        /* background-color: aqua; */

    }

    .cItem:last-child>div:last-child {
        /* padding: 1.8vmax 2.5vmax; */
        padding-left: 2vmax;
        padding-right: 0;

    }

    .cItem>div:last-child>h1 {
        font: 600 2.2vmax 'Roboto';
        color: var(--textC1);
    }

    .cItem>div:last-child>p {
        font: 500 1.8vmax 'Roboto';
        color: var(--textC1);
    }

    /* contactBox end */
    /* reviewCotainer */
    .reviewCotainer {
        width: 100%;
        padding: 4vmax 1vmax;
    }

    .reviewitem {
        min-width: 100%;
        /* background-color: var(--bg4); */
        border: 1px solid var(--bg3);
        padding: 1.5rem;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .reviewitem>div>p {
        text-align: justify;
        font: 300 1.9vmax 'Roboto' !important;
    }

    /* productsBox */
    .productsBox {
        width: 100%;
        /* padding: 2vmax; */
        padding: 4vmax 0vmax;

    }

    .products-row {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.4rem;
    }

    .productsBox>h1 {
        text-align: center;
        padding-bottom: 3vmax;
        font: 600 2.6vmax 'Roboto';
        color: var(--textC1);
        /* border-bottom: 1px solid var(--bg3); */
    }

    /* productsBox end  */

    /* aboutBox */
    .aboutBox {
        width: 100%;
        padding: 5vmax 2vmax;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        background-color: rgb(232, 232, 232);
        margin-top: 4vmax;
    }

    .aboutBox>div {
        width: 100%;
        /* padding: 1vmax 0; */
    }

    .aboutBox>div:first-child>p,
    .aboutBox>div:first-child>h2,
    .aboutBox>div:first-child>h4 {

        line-height: 32px !important;
    }

    .aboutBox>div:first-child>h2 {
        font: 600 3vmax 'Roboto';

    }

    .aboutBox>div:first-child>h4 {
        font: 600 2vmax 'Roboto';

    }

    .aboutBox>div:first-child>p {
        font: 300 2vmax 'Roboto';

    }
}