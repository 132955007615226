.footerContainer{
    max-width: 100%;
    width: 100vw;
    /* height: 50vh; */
    /* padding: 3rem 7rem; */
    padding-top: 1vmax;
    background-color: var(--textC1);
    background: linear-gradient(25deg , var(--bg3),var(--textC2));
   
    bottom: 0;
    color: var(--textC1);
}
.topBox{
    /* padding-top: -2rem; */
    /* height: 44vh; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* flex-wrap: wrap ; */
    padding: 2rem  10vmax;

}
.downBox{
    /* height: 5vh; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem  2vmax;
    background-color: var(--textC1) !important;
    border-top: 1px solid rgb(238, 238, 238);

}
.downLeftBox{
    /* border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;

}


.downLeftBox > p {
    color: var(--textLight);
    /* font: 300 1vmax 'Roboto'; */
}

.downRightBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1vmax;
    /* border: 1px solid gray; */
}
.downRightBox > a {
    text-decoration: none;
    color: var(--textDark);
    display: flex;
    font: 600 1.2vmax 'Roboto';
    color: var(--textC1);
    align-items: center;
    /* border: 1px solid gray; */
}

.downRightBox > a > svg{
    color: var(--textC1);
    transition: 0.5s ease-in; 
    font-size: 1.6vmax;
    margin: 0  0.4rem;

} 
.downRightBox > a > svg:hover{
    /* background-color: white; */
    color: rgb(102, 100, 100);
    transform: scale(1.2);

}

.topBox .box1{
    width: 35%;
    height: 100%;
    padding-right: 2rem;

}
.box1> div> p{
    font-family: "roboto";
    font: 300 0.9rem 'Roboto';
}
.topBox .box2{
    width: 30%;
    height: 100%;   
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */
    gap: 0.6rem;
}
.box2 > div > a{
    font-family: roboto;
    color: var(--textC1);
}
.box2 > div > a >svg{
    color: var(--textC1);

}
.topBox .box3{
    width: 25%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 1rem; */
    gap: 0.6rem;
    /* background-color: antiquewhite; */

}
.box3 > div > img{
    width: 100px;
    height: auto;
}
.box3 > div > h1{
    width: 100%;
  font: 600 1.5vmax 'Roboto' ;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  color: var(--textC1);
}
.Box2heading,
.Box1heading{
    width: 90%;
    padding: 0.8rem 0rem;
    /* background-color: var(--bg2); */
    font: 600 1.1rem "Roboto" ;
    translate: unset;
    color: var(--textC1);
    margin-bottom: 0.5rem;
    /* border-bottom: 1px solid var(--textC2);     */
    /* height: auto; */
    text-transform: uppercase;
}

.atag{
    width: 100%;
    text-decoration: none;
    color: var(--textLight);
    font: 500 0.9rem "Sansita";
    transition: 0.5s ease-in-out;
    /* padding: 0.1rem 0.2rem; */
    /* margin: 0.2rem 0; */
    /* border-bottom: 1px solid rgb(65, 65, 65); */

    display: flex;
    /* justify-content: center; */
    align-items:flex-start;

}
.atag >svg{
    font-size: 1.5vmax;
    color: var(--textC2);
}
.atag:hover{
    color: var(--textC1);
    padding-left: 0.6rem;

}
.colorMidDark{
    color: var(--bg1);
}




.joinBtn{
    border: 2px solid var(--textC2);
    padding: 0.5vmax 0.8vmax;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 1vmax 'Roboto';
    transition: all 0.5s;
    color: var(--textC2);
}
.joinBtn:hover{
    color: var(--hoverText);
}

/* subscribeBox */
.subscribeBox{
    width: 80%;
    margin: 0 auto;
    margin-top: 1vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax 2.5vmax;
    background-color: white;
    color: black;
    border-radius: 0.5rem;
}
.subscribeBox > h2{
    font: 600 2vmax 'Roboto';
    color: var(--bg1);
}
.subscribeBox >div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.subscribeInp{
    border: 1px solid var(--bg1);
    background-color: var(--bg3) !important;
    padding: 0.8vmax;
    border-radius: 0.5rem;

}
.subscribeInp >input{
    font: 300 1.2vmax 'Roboto';
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--textC2);
}
.subscribeInp >input::placeholder{
    color: var(--textC2);

}
.subscribeInp > svg{
    color: white;
    font-size: 1.5vmax;
}
.subscribeInp > svg:hover{
    transition: all 0.5s;
    transform: rotate(-360deg);
    cursor: pointer;
}
.contactBoxs > span > p{
    font: 300 0.9vmax 'Roboto';
    color: var(--textC1);

}
.contactBoxs > svg{
    color: var(--bg1);
    font-size: 2vmax;

}
/* subscribeBox end */
.closeBox{
    width: 100%;
    text-align: right;
    padding-bottom: 5px;
}
.closeBox > button{
    padding: 0.2rem 1rem;
    border: 1px solid gray;
    background-color: white;
    border-radius: 2rem;
    cursor: pointer;
}
.closeBox > button:hover{
    background-color: var(--bg1);
    color: white;
    border: none;
}
@media screen and (max-width:952px){
    .footerContainer{
        max-width: 100%;
        width: 100vw;
        /* height: 50vh; */
        /* padding: 2rem 0rem; */
        /* margin-top: rem; */
        bottom: 0;
        /* color: var(--textC2); */
    }

   .topBox{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem  1rem;

}
.Box2heading,
.Box1heading{
    width: 90%;
    padding: 0.2rem 0rem;
    /* background-color: var(--bg2); */
    font: 600 1.1rem "Roboto" ;
    translate: unset;
    /* color: var(--bg3); */
    margin-bottom: 0rem;
    border-bottom: 1px solid var(--textC1);    
    /* height: auto; */
    text-transform: uppercase;
}
.downBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem ;
    text-align: center;
    gap: 1rem;
    /* border-top: 2px solid rgb(11, 11, 11); */
}
.downLeftBox > p {
    color: var(--textLight);
    font: 300 1.8vmax 'Roboto';
}
.topBox .box1{
    width: 100%;
    /* text-align: center; */
}
.topBox .box2{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.6rem;
    /* margin: 1rem 0; */
    padding-top: 2rem;
}
.atag >svg{
    font-size: 3.5vmax;
    color: var(--textC2);
}
.topBox .box3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding-top: 0;
    text-align: center;
}
.downRightBox > a{
    font-size: medium;
}

.downRightBox > a > svg{
    /* color: white; */
    transition: 0.5s ease-in; 
    font-size: 2.6vmax;
    margin: 0  0.4rem;

} 
.joinBtn{
    padding: 0.6vmax 0.9vmax;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 1.6vmax 'Roboto';
    transition: all 0.5s;
}
.box3 > div > img{
    width: 60px;
    height: auto;
}
.box3 > div > h1{
    width: 80%;
  font: 600 2vmax 'Roboto' ;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
}
/* subscribeBox */
.subscribeBox{
    width: 90%;

    margin: 0 auto;
    margin-top: 1vmax;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 2.5vmax 1vmax;
    background-color: white;
    color: black;
    border-radius: 0.5rem;
}
.subscribeBox > h2{
    font: 600 3vmax 'Roboto';
    color: var(--bg1);
}
.subscribeInp{
    width: 80%;
    border: 1px solid var(--bg1);
    background-color: var(--bg3) !important;
    padding: 1.4vmax;
    border-radius: 0.5rem;

}
.subscribeInp >input{
    width: 80%;
    font: 300 2vmax 'Roboto';
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--textC2);
}
.subscribeInp > svg{
    /* color: white; */
    font-size: 2.5vmax;
}
.contactBoxs > span > p{
    font: 300 1.6vmax 'Roboto';
    color: var(--textC1);

}
.contactBoxs > svg{
    /* color: var(--bg1); */
    font-size: 3.5vmax;

}
.modal{
    width: 99% !important;
    height: 60vh !important;
    background-color: white;
    padding: 1rem 0.5rem !important;
    margin: 20vh auto !important;
}
}

.modal{
    width: 80%;
    height: 95vh;
    margin: 1% auto;
    background-color: white;
    padding: 2rem ;
    outline: none;
}